import { MaintenanceService } from './../../maintenance.service';
import { ManageBucketService } from 'app/dashboard/buckets/manage-bucket.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewTripsService } from './new-trips.service';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { Store } from '@ngrx/store';
import { selectorUser, selectorActingAs, selectorBusinesses } from 'app/auth/auth.reducer';
import { AuthUser, ActingAs, UserRoles } from 'app/interfaces/auth.interfaces';
import { FleetAllocationEnum, OrderSourceEnum, Parcel, Viewer, ViewerDictionary, RecreateCourierOrderResponse } from './new-trips.interfaces';
import { AuthService } from 'app/auth/auth.service';
import { setActiveTripId, setActiveTripWarehouseId, clearActiveTripStore } from './store/new-trips.actions';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { BasicButton, ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { MenuDropDownItems } from 'app/shared/menu-dropdown/menu-drop-down.interfaces';
import { NotesService } from '../notes/notes.service';
import { ConfirmInputModalComponent } from 'app/shared/modals/confirm-input-modal/confirm-input-modal.component';
import { ToastService } from 'app/shared/toast/toast.service';
import { TripMenuItemEnum } from './new-trips.enums';
import { NotificationTypeEnum } from 'app/shared/shared.interfaces';
import { parcelTableColumns, parcelTableDataMap } from './new-trips.constants';
import { TableActionTaken, TableCell } from 'app/shared/table/table.interfaces';
import { NewTripsActivationDialogComponent } from './new-trips-activation-dialog/new-trips-activation-dialog.component';
import { SettingsService } from '../settings/settings.service';
import { LotteryOptions } from '../settings/lottery-options/lottery-options.interface';
import { OpenNewTripDialogComponent } from './open-new-trip-dialog/open-new-trip-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

interface DriverDetails {
  name: string;
  id: string;
  lastMileId: string;
}

@Component({
  selector: 'app-new-trips',
  templateUrl: './new-trips.component.html',
  styleUrls: ['./new-trips.component.scss'],
})
export class NewTripsComponent implements OnInit, OnDestroy {
  public JustifyContent = JustifyContent;
  public user: AuthUser;
  public actingAs: ActingAs;
  public ButtonTypes = ButtonTypes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  public UiThemes = UiThemes;
  OrderSourceEnum = OrderSourceEnum;
  FleetAllocationEnum = FleetAllocationEnum;
  viewTabs: BasicButton[] = [];
  public menuOptions: MenuDropDownItems[] = [];
  public id: string;
  fsOrderDetails;
  orderPODs;
  markers = [];
  viewers: Viewer[] = [];
  orderViewSubscription: Subscription;
  firestoreSubscription: Subscription;
  firestorePODSubscription: Subscription;
  orderNotesSubscription: Subscription;
  newNotesSubscription: Subscription;
  orderLotterySubscription: Subscription;
  lotteries = [];

  tabs$;
  activeTab = 'Summary';
  activeDriverId: string = '';
  activeLastMileId: string;
  orderNotes = [];
  newNotesPresent = false;
  processing = false;
  order_waybill: string;
  validParcels: string[];
  orderWaybills: string[];
  polyline = null;

  // Me creating my own read-models, not sure if this should be done on the server rather:

  uniqueWaypoints = new Set();
  uniqueLastMileIds = new Set();
  driverList: DriverDetails[] = [];
  removeViewingDoc: boolean = false;
  businessReferenceBreakdown;
  businesses: any[] = [];
  orderTabs: string[] = ['Summary'];
  driverParcels: Parcel[];
  parcelColumns = parcelTableColumns;
  parcelDataMap = parcelTableDataMap;
  parcelBulkActions = [
    { event: 'unassign', title: 'Unassign', icon: IconTypes.Cross, icon_color: UiColors.Red },
  ];
  lotteryOptions: LotteryOptions;
  unsubscribe$ = new Subject<void>();

  readonly isCourierRecreateLoading$ = this.newTripsService.isLoading$;
  constructor(
    private manageBucketService: ManageBucketService,
    private newTripsService: NewTripsService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private authService: AuthService,
    private notesService: NotesService,
    private simpleModalService: SimpleModalService,
    private maintenanceService: MaintenanceService,
    private toastService: ToastService,
    private settingsService: SettingsService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.notesService.getOrderNotes(this.id);
    });
    this.order_waybill = this.route.snapshot.queryParamMap.get('waybill');
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectorBusinesses).subscribe((next) => (this.businesses = next));

    this.initData();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => (this.tabs$ = this.getActiveTab(this.route.root)));
    this.tabs$ = this.getActiveTab(this.route.root);

    window.onunload = () => {
      this.newTripsService.removeFirestoreDoc(this.id, this.user.user_id);
      this.removeViewingDoc = true;
    };

    window.onbeforeunload = () => {
      this.newTripsService.removeFirestoreDoc(this.id, this.user.user_id);
      this.removeViewingDoc = true;
    };
  }

  ngOnInit(): void {
    if (this.id) {
      this.store.dispatch(new setActiveTripId(this.id));
    }
    this.currentlyViewing();
  }

  ngOnDestroy(): void {
    this.firestoreSubscription?.unsubscribe();
    this.newTripsService.removeFirestoreDoc(this.id, this.user.user_id);
    this.newTripsService.destroyLotterySubscription();
    this.orderViewSubscription?.unsubscribe();
    this.orderNotesSubscription?.unsubscribe();
    this.firestorePODSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new clearActiveTripStore());
  }

  getLotteryOptions(): Promise<void> {
    return this.settingsService.getLotteryOptions().then((ops) => {
      this.lotteryOptions = ops;
    });
  }

  getOrderNotes(): void {
    this.orderNotesSubscription = this.notesService.orderNotes$.subscribe((data) => {
      this.orderNotes = data;
    });
    this.newNotesSubscription = this.notesService.newNotes$.subscribe((data) => {
      if (data) {
        this.newNotesPresent = true;
      } else {
        this.newNotesPresent = false;
      }
      this.viewTabs = this.setViewTabs();
    });
  }

  checkNotesViewed(): void {
    this.newNotesPresent = false;
    this.viewTabs = this.setViewTabs();
    if (this.orderNotes) {
      this.orderNotes.forEach((note) => {
        if (!note.read_by || !note.read_by.includes(this.user.user_id)) {
          this.newNotesPresent = true;
          this.viewTabs = this.setViewTabs();
        }
      });
    }
  }

  subscribeLottery(): void {
    this.orderLotterySubscription = this.newTripsService.orderLotteries.subscribe((data) => {
      this.lotteries = data;
    });
  }

  setViewTabs(): any {
    if (this.user && this.fsOrderDetails) {
      return [
        { type: ButtonTypes.Primary, title: 'Details' },
        { type: ButtonTypes.Primary, title: 'Timeline' },
        {
          type: ButtonTypes.Primary,
          title: 'Lottery',
          hidden:
            (!this.user.is_admin && this.fsOrderDetails?.FleetAllocation !== 'Contractor') ||
            (this.fsOrderDetails?.FleetAllocation !== 'Picup' &&
              this.fsOrderDetails?.FleetAllocation !== 'Contractor' &&
              this.fsOrderDetails.FleetAllocation !== 'PicupPlus'),
        },
        {
          type: this.newNotesPresent ? ButtonTypes.Highlight : ButtonTypes.Primary,
          title: 'Notes',
          hidden: !this.picupAdminOnlyOnPicupOrders()
        },
      ];
    }
  }

  reduceParcelDetails(parcel): any {
    return {
      OneTimePin: parcel.OneTimePin,
      Dimensions: parcel.Dimensions,
      ParcelReference: parcel.ParcelReference,
      ParcelWaybill: parcel.ParcelWaybill,
      ParcelValue: parcel.ParcelValue,
      PaymentType: parcel.PaymentType,
      OrderParcelState: parcel.OrderParcelState,
      Barcode: parcel.Barcode,
      ReconciledReason: parcel.ReconciledReason,
      ActiveRouteId: parcel.ActiveRouteId,
      CourierReference: parcel.CourierReference,
      CourierParcelState: parcel.CourierParcelState,
      FinalizedAt: parcel.FinalizedAt,
      FailedReason: parcel.FailedReason,
    };
  }

  createSummaryData(parcels): void {
    this.uniqueWaypoints = new Set();
    this.uniqueLastMileIds = new Set();
    this.businessReferenceBreakdown = {};
    this.driverList = [];
    parcels.forEach((parcel) => {
      const driverExists = this.driverList.find((x) => x.id === parcel.DriverId);
      if (!driverExists && parcel.DriverId) {
        this.driverList.push({ id: parcel.DriverId, name: parcel.DriverName, lastMileId: parcel.LastMileId });
        // (parcel.OrderParcelState === 'StagedAssignment' ? null : parcel.LastMileId)
      }
      const parcelBusinessReference = parcel.BusinessReference;
      const origin = parcel.OriginWarehouse ? parcel.OriginWarehouse : parcel.Origin;
      const destination = parcel.DestinationWarehouse ? parcel.DestinationWarehouse : parcel.Destination;
      if (Object.keys(this.businessReferenceBreakdown).includes(parcelBusinessReference)) {
        this.businessReferenceBreakdown[parcelBusinessReference].Parcels.push(this.reduceParcelDetails(parcel));
      } else {
        this.businessReferenceBreakdown[parcelBusinessReference] = {
          Parcels: [this.reduceParcelDetails(parcel)],
          Origin: origin,
          OneTimePin: parcel.OneTimePin,
          LastMileId: parcel.LastMileId,
          CourierOrderId: parcel.CourierOrderId,
          Destination: destination,
          BusinessId: parcel.BusinessId,
          OrderId: parcel.OrderId,
          BusinessReference: parcel.BusinessReference,
          DriverName: parcel.DriverName,
          OrderWaybill: parcel.ParcelWaybill.slice(0, 12),
          LatestDeliveredRouteId: parcel.LatestDeliveredRouteId,
          LatestReturnedRouteId: parcel.LatestReturnedRouteId
        };
      }

      this.uniqueWaypoints.add(origin.FormattedAddress);
      this.uniqueWaypoints.add(destination.FormattedAddress);
      // Dont want to show last mile tabs for last miles that have completed all their deliveries
      if (
        parcel.OrderParcelState !== 'Delivered' &&
        parcel.OrderParcelState !== 'Cancelled' &&
        parcel.OrderParcelState !== 'Returned'
      ) {
        this.uniqueLastMileIds.add(parcel.LastMileId);
      }
    });
    this.businessReferenceBreakdown = Object.values(this.businessReferenceBreakdown);
    // Clean up undefined items from above loop
    this.uniqueWaypoints.delete(undefined);
    this.uniqueLastMileIds.delete(null);
    // Go back to summary if last miles are completed
    if (this.uniqueLastMileIds.size === 0) {
      this.activeTab = 'Summary';
    }
  }

  initData(): void {
    this.getTripData();
    this.getOrderNotes();
    this.newTripsService.getLotteryObject(this.id);
    this.subscribeLottery();
  }

  // Matt's big fivehead paly
  getTripData(): void {
    this.fsOrderDetails = undefined;
    this.firestoreSubscription = this.newTripsService.getOrderDetailsObject(this.id).subscribe((res: any) => {
      if (res) {
        if (!this.businesses.find((x) => x.business_id === res.BusinessId)) {
          this.toastService.publish({
            message: 'You do not have permission to view this order',
            type: NotificationTypeEnum.Error,
          });
          localStorage.clear();
          this.authService.logout();
        }
        this.fsOrderDetails = res;
        this.createSummaryData(res.Parcels);
        this.populatePODs();
        this.getLotteryOptions().then(() => {
          this.updateMenus(res);
        });
        this.viewTabs = this.setViewTabs();
        this.store.dispatch(new setActiveTripWarehouseId(res.WarehouseId));
        this.handleActingAs(res.BusinessId);
        if(this.actingAs.roles.includes(UserRoles.SuperAdministrator)){
          this.parcelBulkActions.push({ event: 'force-assign', title: 'Force Assignment', icon: IconTypes.Truck, icon_color: UiColors.Blue });
        }
        if(this.fsOrderDetails.OrderState === 'ScheduledToAssign'){
          this.parcelBulkActions = [];
        }
      } else {
        this.newTripsService.rehydrateAggregate(this.id);
      }
    });
  }

  populatePODs(): void {
    this.firestorePODSubscription = this.newTripsService.getOrderPodObject(this.id).subscribe((res: any) => {
      if (res) {
        this.orderPODs = res;
      }
    });
  }

  // This moves you to the business that placed the trip assuming that you have not selected that business already
  handleActingAs(businessId: string): void {
    if (this.fsOrderDetails.BusinessId !== this.actingAs.id) {
      this.authService.setActingAs(businessId, false);
    }
  }

  constructMapData(): void {
    this.markers = [];
    this.businessReferenceBreakdown.forEach((business_ref) => {
      const marker1 = {
        latitude: business_ref.Destination.Latitude,
        longitude: business_ref.Destination.Longitude,
        status: business_ref.mapMarkerColor,
        label: 'D',
      };
      const marker2 = {
        latitude: business_ref.Origin.Latitude,
        longitude: business_ref.Origin.Longitude,
        status: business_ref.mapMarkerColor,
        label: 'C',
      };
      this.markers.push(marker1, marker2);
    });
    if (this.fsOrderDetails?.FleetAllocation !== 'None') {
      this.getOrderPolyline();
    }
  }

  getOrderPolyline(): void {
    if (!this.polyline) {
      this.newTripsService.getOrderPolyline(this.id).then((res) => (this.polyline = res));
    }
  }

  getActiveTab(route: ActivatedRoute, url: string = '', breadcrumbs = []): any[] {
    // Initial route goes below if you want to add later
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : '';
    const path = route.routeConfig ? route.routeConfig.path : '';

    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      return this.getActiveTab(route.firstChild, nextUrl, newBreadcrumbs);
    }
    newBreadcrumbs.splice(0, 1);
    // TODO: REMOVE THIS WHEN FULL SITE IS FLESHED OUT
    return newBreadcrumbs;
  }

  currentlyViewing(): void {
    this.orderViewSubscription = this.newTripsService
      .getOrderViewObject(this.id)
      .subscribe((result: ViewerDictionary) => {
        if (result) {
          this.viewers = Object.values(result);
        }

        if (!result || (!result[this.user.user_id] && !this.removeViewingDoc)) {
          const data = {
            [this.user.user_id]: {
              is_admin: this.user.is_admin,
              user_name: this.user.name,
            },
          };
          this.newTripsService.addFirestoreDoc(this.id, data);
        }

        if (this.viewers.length > 1 && this.user.is_admin) {
          this.showCurrentlyViewing();
        }
      });
  }

  showCurrentlyViewing(): void {
    this.toastService.publish({
      message: `Currently viewing: ${this.viewers
        .map((x) => {
          return x.user_name;
        })
        .toString()}`,
      type: 'info',
      icon: 'eye.svg',
    });
  }

  updateMenus(fsOrderDetails): void {
    this.menuOptions = this.setTripMenuItems(fsOrderDetails);
  }

  setTripMenuItems(fsOrderDetails): MenuDropDownItems[] {
    if (fsOrderDetails.OrderState === 'Completed') {
      return [
        {
          name: TripMenuItemEnum.Debrief,
          displayName: 'Debrief Report',
        },
        {
          name: TripMenuItemEnum.RecreateTrip,
          displayName: 'Recreate Trip'
        },
        {
          name: TripMenuItemEnum.AllFailedFinalCollections,
          displayName: 'Remove from FFC Tab'
        }
      ];
    } else if (fsOrderDetails.OrderState === 'Cancelled') {
      return [
        {
          name: TripMenuItemEnum.RecreateTrip,
          displayName: 'Recreate Trip'
        },
      ];
    } else {
      return [
        {
          name: TripMenuItemEnum.AttemptDirectAssignment,
          displayName: 'Attempt Direct Assignment',
          disabled:
            !this.actingAs.roles.includes(UserRoles.SuperAdministrator) ||
            fsOrderDetails.FrontEndState === 'LotteryRunning',
          hidden: !this.user.is_admin || !this.lotteryOptions?.is_direct_assignment_enabled,
        },
        {
          name: TripMenuItemEnum.AssignDriver,
          displayName: 'Assign Driver',
          disabled: fsOrderDetails?.FleetAllocation === 'None' || this.fsOrderDetails.Parcels.some((x) => x.DriverId),
        },
        {
          name: TripMenuItemEnum.AssignParcels,
          displayName: 'Assign Parcels',
          disabled: fsOrderDetails?.FleetAllocation === 'None',
          hidden: !this.picupAdminOnlyOnPicupOrders()
        },
        {
          name: TripMenuItemEnum.DownloadWaybills,
          displayName: 'Download Waybills',
          hidden: !this.picupAdminOnlyOnPicupOrders()
        },
        {
          name: TripMenuItemEnum.CancelOrder,
          displayName: 'Cancel Order',
          hidden: !this.picupAdminOnlyOnPicupOrders()
        },
        {
          name: TripMenuItemEnum.ForceCompleteOrder,
          displayName: 'Force Complete',
          hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
        },
        {
          name: TripMenuItemEnum.RecreateAsCourierOrder,
          displayName: 'Send to Courier',
          hidden: !(this.actingAs.roles.includes(UserRoles.SuperAdministrator) && this.businessHasCourierRecreateEnabled()),
        },
        {
          name: TripMenuItemEnum.UnassignOrderParcels,
          displayName: 'Force Unassign Parcels',
          hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
        },
        {
          name: TripMenuItemEnum.RecreateTrip,
          displayName: 'Recreate Trip'
        },
        {
          name: TripMenuItemEnum.RehydrateOrder,
          displayName: 'Rehydrate Order',
          hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator) && !this.picupAdminOnlyOnPicupOrders(),
        },
        {
          name: TripMenuItemEnum.SelfAssign,
          displayName: 'Assign Self',
          hidden: fsOrderDetails.Reservation
        },
        {
          name: TripMenuItemEnum.RemoveAssignedUser,
          displayName: 'Remove Assigned User',
          hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator) && !fsOrderDetails.Reservation,
        },
      ];
    }
  }

  activate(fleet: FleetAllocationEnum): void {
    this.processing = true;
    this.newTripsService.startLotteryBackground(this.id, fleet).then(() => {
      setTimeout(() => {
        this.processing = false;
        this.getTripData();
      }, 5000);
    });
  }

  backButton(): void {
    this.location.back();
  }

  handleTabNavigation($event) {
    if ($event !== 'Details') {
      this.router.navigate(['dashboard/orders/trip/' + this.id, { outlets: { Trip: [$event.toLowerCase()] } }]);
    } else {
      this.router.navigate(['dashboard/orders/trip/' + this.id]);
    }
  }

  handleActionClick($event: string): void {
    const orderWaybills = [];
    switch ($event) {
      case TripMenuItemEnum.CancelOrder:
        this.simpleModalService
          .addModal(ConfirmInputModalComponent, {
            title: 'Cancel Order',
            message: 'Are you sure you wish to cancel this order? To confirm, please type "cancel" as shown',
            key: 'cancel',
          })
          .subscribe((result) => {
            if (!result) {
              return;
            }
            this.newTripsService.handleTripActions(
              this.id,
              $event,
              this.fsOrderDetails.Parcels,
              this.fsOrderDetails.WarehouseId
            );
          });
        break;
      case TripMenuItemEnum.DownloadWaybills:
        this.businessReferenceBreakdown.forEach((element) => {
          orderWaybills.push(element.OrderWaybill);
        });
        this.manageBucketService.printWaybill(orderWaybills);

        break;
      case TripMenuItemEnum.ForceCompleteOrder:
        this.simpleModalService
          .addModal(ConfirmInputModalComponent, {
            title: 'Force Complete Order',
            message: 'Are you sure you wish to force-complete this order? To confirm, please type "force" as shown',
            key: 'force',
          })
          .subscribe((result) => {
            if (!result) {
              return;
            }
            this.maintenanceService.forceCompleteOrders(this.id);
          });
        break;
      case TripMenuItemEnum.UnassignOrderParcels:
        this.simpleModalService
          .addModal(ConfirmInputModalComponent, {
            title: 'Force Unassign Parcels',
            message: 'Are you sure you wish to unassign these parcels? To confirm, please type "force" as shown',
            key: 'force',
          })
          .subscribe((result) => {
            if (!result) {
              return;
            }
            this.maintenanceService.unassignOrderParcels(this.id);
          });
        break;
      case TripMenuItemEnum.RecreateTrip:
        this.simpleModalService
          .addModal(ConfirmInputModalComponent, {
            title: 'Recreate This Trip',
            message: 'Are you sure you wish to recreate this trip? To confirm, please type "recreate" as shown',
            key: 'recreate',
          })
          .subscribe((result) => {
            if (!result) {
              return;
            }
            this.maintenanceService.recreateTrip(this.id).then((res) => {
              this.simpleModalService.addModal(OpenNewTripDialogComponent).subscribe((openTrip) => {
                if (!openTrip) {
                  return;
                }

                window.open(window.location.origin + '/dashboard/orders/trip/' + res.OrderId);
              });
            });
          });
        break;
      case TripMenuItemEnum.RehydrateOrder:
        this.maintenanceService.rehydrateFirestoreReadmodel(this.id, this.id);
        break;
      case TripMenuItemEnum.Debrief:
        this.manageBucketService.downloadDebrief(this.id);
        break;
      case TripMenuItemEnum.AttemptDirectAssignment:
        this.newTripsService.attemptDirectAssignment(this.id);
        break;
      case TripMenuItemEnum.SelfAssign:
        this.newTripsService.reserveOrderForUser(this.id)
        break;
      case TripMenuItemEnum.RemoveAssignedUser:
        this.newTripsService.unreserveOrderForUser(this.id);
        break;
      case TripMenuItemEnum.AllFailedFinalCollections:
        this.newTripsService.removeFailedFinalCollections(this.id).then(response => {
          if(response.status === 200){
            this.toastService.publish({
              message: 'Success',
              type: NotificationTypeEnum.Success,
            });
          } else {
            this.toastService.publish({
              message: 'Failed',
              type: NotificationTypeEnum.Error,
            });
          }
        });
        break;
      case TripMenuItemEnum.RecreateAsCourierOrder:
      {
        // confirmation dialog
        this.simpleModalService
          .addModal(ConfirmInputModalComponent, {
            title: 'Send to courier?',
            message: 'Are you sure you wish to send this order to a courier? To confirm, please type "courier" as shown',
            key: 'courier',
          })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            if (!result) {
              return;
            }
            this.newTripsService.recreateCourierOrder(this.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
              next: (res: RecreateCourierOrderResponse) => {
                if(res.is_success){
                  this.simpleModalService.addModal(OpenNewTripDialogComponent)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((openTrip) => {
                      if (!openTrip) {
                        return;
                      }
                      window.open(window.location.origin + '/dashboard/orders/trip/' + res.new_order_Id);
                    })
                } else {
                  this.toastService.publish({
                    message: 'Failed to recreate',
                    type: NotificationTypeEnum.Error,
                  });
                }
              },
              error: (error: HttpErrorResponse) => {
                this.newTripsService.notify(false, error?.error?.message);
              },
              complete: () => {
                this.newTripsService.isLoading = false;
              }});
          });
        break;
      }
      default:
        this.newTripsService.handleTripActions(
          this.id,
          $event,
          this.fsOrderDetails.Parcels,
          this.fsOrderDetails.WarehouseId
        );
        break;
    }
  }

  goToBucket(): void {
    this.router.navigate(['dashboard/buckets/', { outlets: { Buckets: [this.fsOrderDetails.BucketId] } }]);
  }

  handleESActionClick(): void {
    window.open(environment.eventStoreStream + this.id);
  }

  handleFirebaseClick(): void {
    window.open(
      'https://console.firebase.google.com/u/0/project/' +
        environment.firebase.projectId +
        '/firestore/data/orders/' +
        this.id
    );
  }

  hasActiveParcels(lastMileId: string): boolean {
    return this.fsOrderDetails.Parcels.some(
      (x: Parcel) => x.LastMileId === lastMileId && x.OrderParcelState !== 'StagedAssignment'
    );
  }

  hasStagedParcels(lastMileId: string): boolean {
    return this.fsOrderDetails.Parcels.some(
      (x: Parcel) => x.LastMileId === lastMileId && x.OrderParcelState === 'StagedAssignment'
    );
  }

  showDriverParcels(item: DriverDetails): void {
    if (item.id) {
      this.driverParcels = this.fsOrderDetails.Parcels.filter((x: Parcel) => x.DriverId === item.id).map(
        (y: Parcel) => {
          if (y.Destination) {
            return { ...y, CustomerName: y.Destination.Contact.Name, FormattedAddress: y.Destination.FormattedAddress };
          } else if (y.DestinationWarehouse) {
            return {
              ...y,
              CustomerName: y.DestinationWarehouse.WarehouseName,
              FormattedAddress: y.DestinationWarehouse.FormattedAddress,
            };
          }
        }
      );
    } else {
      this.driverParcels = this.fsOrderDetails.Parcels.filter((x: Parcel) => x.DriverName === item.name).map(
        (y: Parcel) => {
          if (y.Destination) {
            return { ...y, CustomerName: y.Destination.Contact.Name, FormattedAddress: y.Destination.FormattedAddress };
          } else if (y.DestinationWarehouse) {
            return {
              ...y,
              CustomerName: y.DestinationWarehouse.WarehouseName,
              FormattedAddress: y.DestinationWarehouse.FormattedAddress,
            };
          }
        }
      );
    }
    this.activeTab = item.name;
    this.activeDriverId = item.id;
  }

  showDriverLastMile(lastMileId: string): void {
    this.activeTab = lastMileId;
    this.activeDriverId = this.driverList.find((x) => x.lastMileId === lastMileId).id;
    this.driverParcels = this.getDriverParcels(lastMileId);
  }

  goToDriver(driverId: string): void {
    if (!driverId) {
      return;
    }
    window.open(window.location.origin + '/dashboard/manage-fleet/drivers/' + driverId, '_blank');
  }

  handleTableActions($event: TableActionTaken): void {
    const parcels: string[] = [];
    $event.rows.forEach((element) => {
      const waybill = element.cells.find((cell: TableCell) => cell.column === 'ParcelWaybill').value.toString();
      const parcel: Parcel = this.fsOrderDetails.Parcels.find((x: Parcel) => x.ParcelWaybill === waybill);
      if (parcel.OrderParcelState === 'Delivered') {
        return;
      }
      parcels.push(waybill);
    });
    switch ($event.action.event) {
      case 'unassign': {
        // this.lastMileService.unassignStagedParcels(parcels, this.driverLastMileId);
        break;
      }
      case 'force-assign': {
        this.newTripsService.forceAssignStagedParcels(parcels, this.id);
        break;
      }
    }
  }

  getDriverParcels(lastMileId: string): Parcel[] {
    const parcels = this.fsOrderDetails.Parcels.filter((x: Parcel) => x.LastMileId === lastMileId).map((y: Parcel) => {
      if (y.Destination) {
        return { ...y, CustomerName: y.Destination.Contact.Name, FormattedAddress: y.Destination.FormattedAddress };
      } else if (y.DestinationWarehouse) {
        return {
          ...y,
          CustomerName: y.DestinationWarehouse.WarehouseName,
          FormattedAddress: y.DestinationWarehouse.FormattedAddress,
        };
      }
    });
    return parcels;
  }

  editActivationTime(): void {
    this.simpleModalService
      .addModal(NewTripsActivationDialogComponent, {
        is_admin: this.user.is_admin,
        trip_fleet: this.fsOrderDetails?.FleetAllocation,
        currentDate: new Date(this.fsOrderDetails?.ScheduledDate?.toMillis()),
      })
      .subscribe((data) => {
        if (!data) {
          return;
        }

        this.newTripsService
          .scheduleOrder(
            {
              fleet_allocation: data.fleet_allocation,
              scheduled_date: data.scheduled_date,
            },
            this.id
          )
          .then(() => {
            this.processing = false;
          })
          .catch(() => {
            this.processing = false;
          });
      });
  }

  resetToDefaultView(): void {
    this.activeTab = 'Summary';
  }

  goToUser(assignmentMethod: string): void {
    const userId = assignmentMethod.split(' ');
    if(userId.length === 3){
      if (userId[2].includes('user')) {
        window.open(`${window.location.origin}/admin/users/${userId[2]}`);
      }
    }
  }

  picupAdminOnlyOnPicupOrders(): boolean {
    // commented out, add back after release
    return true;
    // return (this.user.is_admin &&
    // (this.fsOrderDetails?.FleetAllocation === 'Picup' ||
    // this.fsOrderDetails.FleetAllocation === 'PicupPlus')) ||
    // this.fsOrderDetails?.FleetAllocation === 'Contractor'
  }

  private businessHasCourierRecreateEnabled(): boolean {
    return this.newTripsService.getCourierOrderConfigEnabledBusinessUids()?.some(business => business === this.actingAs.id);
  }
}
