import { Injectable, OnDestroy } from '@angular/core';
import { AutoNotesData, AutoNotesTypes, notesModel, NotesTypeEnum } from './notes.constants';
import { NotesService } from './notes.service';
import { AuthUser } from 'app/interfaces/auth.interfaces';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { selectorUser } from 'app/auth/auth.reducer';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutoNotesService implements OnDestroy {

  private unsubscribe$ = new Subject<void>();
  private user: AuthUser;

  constructor(private noteService: NotesService, private store: Store) {
    this.store.select(selectorUser).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => {
      this.user = next;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  generateNote(autoNotesData: AutoNotesData): void{
    const content = this.autoNotesContent(autoNotesData)
    const note: notesModel  = {
      // auth user
      author_id: this.user.user_id,
      author_name: this.user.name,
      // business id
      owner: '',
      order_id: autoNotesData.orderId ?? null,
      bucket_id: null,
      driver_id: autoNotesData.driverId ?? null,
      driver_name: null,
      read_by: [],
      origin: autoNotesData.orderId !== null ? NotesTypeEnum.Order : NotesTypeEnum.Driver,
      content: content,
      category: 'Auto Generated Note',
      // TODO: check if needs to be public
      public: false,
    }

    this.noteService.saveNote(note);
  }

  private autoNotesContent(data: AutoNotesData): string {
    switch (data.autoNotesType) {
      case AutoNotesTypes.userAssigned:
        return `${this.user.name} assigned themselves to the order`

      case AutoNotesTypes.userRemoved:
        return `${this.user.name} removed the assigned user`

      case AutoNotesTypes.driverAssigned:
        return `${this.user.name} assigned the driver ${data.driverName} to the order`

      case AutoNotesTypes.driverRemoved:
        return `${this.user.name} removed the driver ${data.driverName} from the order`

      case AutoNotesTypes.lotteryRun:
        return `${this.user.name} ran the lottery ${data.fleet? 'for ' + data.fleet: ''}`

      case AutoNotesTypes.adjustedSchedule:
        return `${this.user.name} changed the scheduled time`

      case AutoNotesTypes.rehydrateOrder:
        return `${this.user.name} rehydrated the order`

      case AutoNotesTypes.parcelsUnassigned:
        return `${this.user.name} unassigned parcels from the order`

      case AutoNotesTypes.reconciledOrder:
        return `${this.user.name} reconciled the order`

      case AutoNotesTypes.lastMileCleared:
        return `${this.user.name} cleared the last mile`

      case AutoNotesTypes.parcelsMoved:
        return `${this.user.name} moved parcels`

      case AutoNotesTypes.driverOnline:
        return `${this.user.name} toggled the driver online`

      case AutoNotesTypes.driverOffline:
        return `${this.user.name} toggled the driver offline`

      case AutoNotesTypes.lotteriesEnabled:
        return `${this.user.name} enabled any lotteries`

      case AutoNotesTypes.plusLotteriesEnabled:
        return `${this.user.name} enabled any Plus lotteries`

      case AutoNotesTypes.directAssignmentDisabled:
        return `${this.user.name} disabled eligible for Direct Assignment`

      case AutoNotesTypes.directAssignmentEnabled:
        return `${this.user.name} enabled eligible for Direct Assignment`

      case AutoNotesTypes.enableEarnings:
        return `${this.user.name} enabled any driver earnings to be seen`

      case AutoNotesTypes.disableEarning:
        return `${this.user.name} disabled any driver earnings to be seen`

      case AutoNotesTypes.driverActivated:
        return `${this.user.name} activated the driver`

      case AutoNotesTypes.driverDeactivated:
        return `${this.user.name} deactivated the driver`

      default:
        break;
    }
    return null
  }
}
