<mat-form-field *ngIf="!(operationsTrackingService.isAdmin$ | async) && warehouses && warehouses?.length > 0 && (operationsTrackingService.teams$ | async).length > 0" appearance="standard">
  <mat-label>Team</mat-label>
  <mat-select [formControl]="teamsControl" multiple>
    <mat-option *ngFor="let team of (operationsTrackingService.teams$ | async); trackBy: trackByTeamName" 
      [value]="team.teamName" >
      {{team.teamName}}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="warehouses && warehouses?.length > 0" appearance="standard">
  <mat-label>Warehouses</mat-label>
  <mat-select
    [formControl]="warehouseControl"
    multiple>
    <input-field
      (valueChanged)="filterWarehouse($event)"
      [type]="'text'"
      [placeholder]="'Search...'"
      [autoComplete]="'off'">
    </input-field>
    <mat-option *ngFor="let warehouse of filteredWarehouses; trackBy: trackByWarehouseId" 
      [value]="warehouse.id">
      {{ warehouse.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field *ngIf="filteredBusiness?.length > 0" appearance="standard">
  <mat-label>Businesses</mat-label>
  <mat-select [formControl]="businessControl" multiple>
    <input-field
      (valueChanged)="filterBusinesses($event)"
      [type]="'text'"
      [placeholder]="'Search...'"
      [autoComplete]="'off'">
    </input-field>
    <mat-option *ngFor="let business of filteredBusiness; trackBy: trackByBusinessId" 
      [value]="business.business_id">
      {{ business.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-label>Fleet</mat-label>
  <mat-select (selectionChange)="applyFilter()" [formControl]="fleetControl">
    <mat-option *ngFor="let fleet of fleets" [value]="fleet === 'All' ? '' : fleet">{{ fleet }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-label>Last Event</mat-label>
  <mat-select (selectionChange)="applyFilter()" [formControl]="lastEventControl">
    <mat-option
      *ngFor="let lastEvent of lastEvents | keyvalue"
      [value]="lastEvent.key === 'All' ? '' : lastEvent.key"
      >{{ lastEvent.value ? lastEvent.value : 'All' }}</mat-option
    >
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-label>On-Demand:</mat-label>
  <mat-select (selectionChange)="applyFilter()" [formControl]="onDemandControl">
    <mat-option
      *ngFor="let onDemand of onDemandArray"
      [value]="onDemand"
      >{{onDemand}}</mat-option
    >
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-label>Assigned Status</mat-label>
  <mat-select (selectionChange)="applyFilter()" [formControl]="assignedStatusControl">
    <mat-option
      *ngFor="let assigned of assignedStatus | keyvalue"
      [value]="assigned.value === 'All' ? 'All' : assigned.value"
      >{{ assigned.value ? assigned.value : 'All' }}
      </mat-option>
  </mat-select>
</mat-form-field>
<div class="header">
  <h3>{{ dataSource.filteredData.length }} {{ orderState }} Order{{ dataSource.data.length === 1 ? '' : 's' }}</h3>
</div>
<table mat-table matSort [dataSource]="dataSource" matSortActive="Timestamp" matSortDirection="desc" class="mat-elevation-z8" [trackBy]="trackBy">
  <ng-container matColumnDef="BusinessName">
    <th mat-header-cell *matHeaderCellDef>Business Name</th>
    <td mat-cell *matCellDef="let element">{{ element.BusinessId | businessName: businesses }}</td>
  </ng-container>

  <ng-container matColumnDef="CustomerReference">
    <th mat-header-cell *matHeaderCellDef>Customer Reference</th>
    <td mat-cell *matCellDef="let element">{{ element.CustomerReference }}</td>
  </ng-container>

  <ng-container matColumnDef="Drivers">
    <th mat-header-cell *matHeaderCellDef>Driver</th>
    <td mat-cell *matCellDef="let element">{{ element.DriverNames }}</td>
  </ng-container>

  <ng-container matColumnDef="WarehouseName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Warehouse Name</th>
    <td mat-cell *matCellDef="let element">{{ element.WarehouseName }}</td>
  </ng-container>

  <ng-container matColumnDef="ScheduledDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduled Date</th>
    <td mat-cell *matCellDef="let element">{{ element.ScheduledDate?.toMillis() | date: 'medium' }}</td>
  </ng-container>

  <ng-container matColumnDef="Timestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Updated</th>
    <td class="pd-l-5 {{ element.Timestamp?.toMillis() | timeAgoColour }}" mat-cell *matCellDef="let element">
      {{ element.Timestamp?.toMillis() | timeAgo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="LastEventName">
    <th mat-header-cell *matHeaderCellDef>Last Event</th>
    <td class="{{ element.LastEventName | lastEventColour }} pd-l-5" mat-cell *matCellDef="let element">
      {{ element.LastEventName | lastEvent }}
    </td>
  </ng-container>

  <ng-container matColumnDef="LotteryRestartCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Lottery Attempts</th>
    <td
      class="pd-l-5"
      [class]="element.TotalLotteryAttempts | lotteryCountColour"
      [class]=""
      mat-cell
      *matCellDef="let element"
    >
      {{ element.TotalLotteryAttempts ?? '-' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="IsRoundTrip">
    <th mat-header-cell *matHeaderCellDef>Trip Type</th>
    <td class="pd-l-5" mat-cell *matCellDef="let element">{{ element.IsRoundTrip ? 'Round Trip' : 'One Way' }}</td>
  </ng-container>

  <ng-container matColumnDef="AssignedTo">
    <th mat-header-cell *matHeaderCellDef>Assigned To</th>
    <td mat-cell *matCellDef="let element">{{ element.Reservation?.Username ?? 'Unassigned'}}</td>
  </ng-container>

  <ng-container matColumnDef="FleetAllocation">
    <th mat-header-cell *matHeaderCellDef>Fleet</th>
    <td mat-cell *matCellDef="let element">{{ element.FleetAllocation }}</td>
  </ng-container>

  <ng-container matColumnDef="Parcels">
    <th mat-header-cell *matHeaderCellDef>Parcels</th>
    <td mat-cell *matCellDef="let element">{{ element.ParcelCount ?? 0 }}</td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td class="actions" mat-cell *matCellDef="let element">
      <mat-icon matTooltip="View" (click)="handleTableAction(element.Id, ActionEnum.View)">visibility</mat-icon>
      <mat-icon matTooltip="Copy ID" (click)="handleTableAction(element.Id, ActionEnum.CopyId)">content_copy</mat-icon>
      <mat-icon
        matTooltip="Restart Lottery"
        *ngIf="allowRestartLottery"
        (click)="handleTableAction(element.Id, ActionEnum.RestartLottery)"
        >refresh</mat-icon
      >
      <mat-icon
        matTooltip="Open Summary"
        (click)="openSummary(element.Id)"
        >summarize</mat-icon
      >
      <mat-icon
      matTooltip="Open Notes"
      (click)="viewNotes(element.Id)"
      >menu</mat-icon
    >
      <mat-icon 
        *ngIf="!element.Reservation"
        matTooltip="Assign to me"
        (click)="assignUser(element.Id)">
        add
      </mat-icon>
      <mat-icon
        *ngIf="isSuperAdmin && element.Reservation"
        matTooltip="Unassign User"
        (click)="unassignUser(element.Id)">
        remove
      </mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="pageSize" showFirstLastButtons> </mat-paginator>
<div class="display-info" *ngIf="this.dataSource.data.length === 0">
  <h4>No {{ orderState }} Orders Currently</h4>
  <mat-spinner></mat-spinner>
  <p>We'll continue to monitor...</p>
</div>
