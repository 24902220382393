import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TeamDetails, WarehouseDetails } from 'app/admin/team-management/Interfaces/team-management';
import { authState, selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { NotesModalComponent } from 'app/dashboard/notes/notes-modal/notes-modal.component';
import { ActingAs, AuthUser, UserRoles, Warehouse } from 'app/interfaces/auth.interfaces';
import { AssignedStatusEnum, OnDemandToggle, OpTrBusinesses, OperationsTrackingTableAction, OperationsTrackingTableActionEnum, OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { LastEventMapping } from 'app/operations-tracking/pipes/last-event.pipe';
import { OrderSummaryPopupComponent } from 'app/shared/order-summary-popup/order-summary-popup.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() orderState: OrderStateEnum;
  @Input() warehouses: Warehouse[] = [];
  @Input() businesses: OpTrBusinesses[] = [];
  @Input() dataSource = new MatTableDataSource<OrderSummary>([]);
  @Input() allowRestartLottery: boolean = false;
  @Input() displayedColumns: string[] = ['BusinessName', 'CustomerReference','WarehouseName','ScheduledDate','LastEventName', 'Timestamp', 'LotteryRestartCount', 'IsRoundTrip', 'FleetAllocation', 'AssignedTo','Parcels' ,'Actions'];

  @Output() tableActionTaken: EventEmitter<OperationsTrackingTableAction> = new EventEmitter();
  @Output() warehouseFilterChange: EventEmitter<string[]> = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  warehouseControl = new FormControl([]);
  businessControl = new FormControl([]);
  teamsControl = new FormControl([]);
  filteredWarehouses = this.warehouses;
  filteredBusiness = this.businesses;
  teams: TeamDetails[] = [];
  fleetControl = new FormControl('All Picup');
  fleets: string[] = ['All', 'All Picup','Picup', 'PicupPlus', 'Contractor'];
  lastEventControl = new FormControl('');
  lastEvents: Map<string, string> = new Map([
    ['All', ''],
    ...LastEventMapping
  ]);
  assignedStatusControl = new FormControl('All');
  assignedStatus = AssignedStatusEnum;
  ActionEnum = OperationsTrackingTableActionEnum;
  isSuperAdmin = false;
  actingAs: ActingAs;
  unsubscribe$ = new Subject<void>();
  onDemandToggle = OnDemandToggle;
  onDemandArray = Object.values(OnDemandToggle);
  onDemandControl = new FormControl(this.onDemandArray[0]);
  selectionValues: string[] = [];
  pageSize = 5;
  isAdminScreen: boolean;
  user: AuthUser;


  constructor(public operationsTrackingService: OperationsTrackingService, private dialog: MatDialog,  private store: Store<authState>) {
    this.fleetControl.setValue(this.operationsTrackingService.fleet)
    operationsTrackingService.teams$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(teams => {
        this.teams = teams;
      });
  }

  ngOnInit(): void {
    this.store.select(selectorActingAs).pipe(takeUntil(this.unsubscribe$)).subscribe((next) => {
      this.actingAs = next;
      if (next.roles.includes(UserRoles.SuperAdministrator)) {
        this.isSuperAdmin = true;
      } else {
        this.isSuperAdmin = false;
      }
    });
    this.store.select(selectorUser).pipe(takeUntil(this.unsubscribe$)).subscribe((user: AuthUser) => (this.user = user));
    this.operationsTrackingService.isAdmin$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: boolean) => {
      this.isAdminScreen = value;
    });
    this.filteredWarehouses = this.warehouses;
    this.filteredBusiness = this.businesses


    this.warehouseControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedWarehouses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedWarehouses];
        this.storeWarehouseFilterPerBusiness();
      });

    this.businessControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedBusinesses: string[]) => {
        this.operationsTrackingService.usedIds = [...selectedBusinesses];
        this.storeFilters();
      });

    this.teamsControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teamNames: string[]) => {
        const warehouses: string[] = [];
        teamNames.forEach((teamName: string) => {
          const teamWarehouses = this.teams.find(value => value.teamName === teamName)?.warehouses ?? [];
          teamWarehouses.forEach((warehouse: WarehouseDetails) => {
            if(!warehouses.some(value => value === warehouse.id)){
              warehouses.push(warehouse.id)
            }
          })
        });
        this.warehouseControl.setValue(warehouses);
      })
    this.loadFilters();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.storeFilters();
    })
    this.applyFilter()
  }

  getOrders(values: string[]): void {
    this.warehouseFilterChange.emit([...values]);
  }

  applyFilter(): void {

    let filterData = {
      hasValue: false,
      valueId: null
    }

    switch (this.assignedStatusControl.value) {
      case AssignedStatusEnum.All:
        {
          filterData = null;
        }
        break;
      case AssignedStatusEnum.Assigned:
        {
          filterData.hasValue = true;
        }
        break;
      case AssignedStatusEnum.AssignedToMe:
        {
          filterData.hasValue = true;
          filterData.valueId = this.operationsTrackingService.userId;
        }
        break;
      case AssignedStatusEnum.Unassigned:
        {
          filterData.hasValue = false;
        }
        break;
      default:
      {
        filterData = null;
      }
    }

    this.dataSource.filterPredicate = (data: OrderSummary) => {
      let returnValue = true;
      if(this.fleetControl.value === 'All Picup'){
        returnValue =  (data.FleetAllocation === 'Picup' || data.FleetAllocation === 'PicupPlus') && returnValue
      } else if(this.fleetControl?.value?.length > 0){
        returnValue = data.FleetAllocation === this.fleetControl.value && returnValue;
      }

      if(this.lastEventControl?.value?.length > 0){
        returnValue = data.LastEventName === this.lastEventControl.value && returnValue;
      }

      switch(this.onDemandControl.value){
        case OnDemandToggle.scheduled: {
          returnValue = data.BucketId !== null && returnValue;
          break;
        }
        case OnDemandToggle.onDemand: {
          returnValue = data.BucketId === null && returnValue;
          break;
        }
      }

      if(filterData?.hasValue){
        if(filterData.valueId){
          // only assigned to current user
          returnValue = data.Reservation?.UserId === filterData.valueId && returnValue
        } else {
        // all that are assigned
          returnValue = data.Reservation?.Username?.length > 0 && returnValue
        }
      } else if(filterData?.hasValue !== undefined && filterData?.hasValue !== null){
        // all unassigned
        returnValue = !data.Reservation && returnValue
      }
      return returnValue
    };
    this.dataSource.filter = 'value';
    this.storeFilters();
  }

  handleTableAction(id: string, action: OperationsTrackingTableActionEnum): void {
    this.tableActionTaken.emit({Action: action, Id: id});
  }

  trackBy(_index: number, item: OrderSummary): string {
    return item.Id;
  }

  openSummary(elementId: string): void{
    this.dialog.open(
      OrderSummaryPopupComponent,
      {
        panelClass: 'noBorder',
        width: '900px',
        height: '280px',
        data:{
          orderId: elementId
        }
      }
    );
  }

  viewNotes(orderId: string): void {
    const dialogRef = this.dialog.open(NotesModalComponent,
      {
        panelClass: 'noBorder',
        height: '400px',
        width: '800px'
      });
    dialogRef.componentInstance.id = orderId;
    dialogRef.componentInstance.isAdmin = this.user.is_admin;
  }

  getBusinessName(id: string): string {
    return this.businesses?.find(business => business.business_id === id)?.name ?? ''
  }

  assignUser(orderId: string): void{
    if(orderId){
      this.operationsTrackingService.reserveOrderForUser(orderId)
    }
  }

  unassignUser(orderId: string): void{
    if(orderId){
      this.operationsTrackingService.unreserveOrderForUser(orderId)
    }
  }

  filterWarehouse(searchTerm: string): void{
    if(searchTerm?.length > 2 ){
      this.filteredWarehouses = this.warehouses.filter((warehouse: Warehouse) => {
        return warehouse?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredWarehouses = this.warehouses;
    }
  }

  filterBusinesses(searchTerm: string): void{
    if(searchTerm?.length > 2 ){
      this.filteredBusiness = this.businesses.filter((business: OpTrBusinesses) => {
        return business?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
    } else {
      this.filteredBusiness = this.businesses;
    }
  }

  trackByWarehouseId(_index: number, item: Warehouse): string{
    return item.id;
  }

  trackByBusinessId(_index: number, item: OpTrBusinesses): string{
    return item.business_id;
  }

  trackByTeamName(_index: number, item: TeamDetails): string {
    return item.teamName;
  }

  storeFilters(): void {

    const filterValues = {
      selectedBusinesses: this.businessControl.value,
      selectedFleet: this.fleetControl.value,
      selectedEvent: this.lastEventControl.value,
      selectedOnDemand: this.onDemandControl.value,
      selectedAssignedStatus: this.assignedStatusControl.value,
      pageSize: this.paginator?.pageSize ?? this.pageSize
    }
    localStorage.setItem('opsFilters', JSON.stringify(filterValues));
  }

  storeWarehouseFilterPerBusiness(): void {
    const fValues = {
      selectedWarehouses: this.warehouseControl.value,
      selectedTeams: this.teamsControl.value,
    }
    localStorage.setItem(`opsFilters-${this.actingAs.id}`, JSON.stringify(fValues));
  }

  loadFilters(): void {
    const filtersGlobal = JSON.parse(localStorage.getItem('opsFilters'));
    this.pageSize = filtersGlobal?.pageSize ?? 5;
    this.onDemandControl.setValue(filtersGlobal?.selectedOnDemand ?? 'All');
    this.assignedStatusControl.setValue(AssignedStatusEnum[filtersGlobal?.selectedAssignedStatus] ?? AssignedStatusEnum.All);
    this.fleetControl.setValue(filtersGlobal?.selectedFleet ?? '');
    this.lastEventControl.setValue(filtersGlobal?.selectedEvent ?? '')

    if(this.isAdminScreen){
      this.businessControl.setValue(filtersGlobal?.selectedBusinesses ?? []);
    }

    if(!this.isAdminScreen){
      const filters = JSON.parse(localStorage.getItem(`opsFilters-${this.actingAs.id}`));
      this.teamsControl.setValue(filters?.selectedTeams ?? []);
      this.warehouseControl.setValue(filters?.selectedWarehouses ?? []);
    }
  }
}
